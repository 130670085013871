.modal {
	align-items: center;
	background: rgba(0, 0, 0, 0.6);
	height: 100%;
	justify-content: center;
	left: 0;
	min-width: 500px;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 1400;
}

.modal-main {
	background: white;
	height: auto;
	left: 50%;
	max-height: 90%;
	overflow-y: scroll;
	padding: 40px;
	position: fixed;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 60%;
}

.modal-md {
	background: white;
	height: auto;
	left: 50%;
	max-height: 90%;
	overflow-y: scroll;
	position: fixed;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 40%;
}

.modal-xl {
	background: white;
	height: auto;
	left: 50%;
	max-height: 90%;
	overflow-y: scroll;
	padding: 30px 20px 20px 20px;
	position: fixed;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 80%;
}

.display-flex {
	display: flex;
}

.display-none {
	display: none;
}
