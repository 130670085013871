input[type='range'] {
	appearance: none;
	height: 38px;
	margin: 10px 0;
	width: 70%;
}
input[type='range']:focus {
	outline: none;
}
input[type='range']::-webkit-slider-runnable-track {
	background: #007167;
	border: 1px solid #000000;
	border-radius: 5px;
	box-shadow: 1px 1px 1px #000000;
	cursor: pointer;
	height: 10px;
	width: 100%;
}
input[type='range']::-webkit-slider-thumb {
	appearance: none;
	background: #ee7600;
	border: 1px solid #000000;
	border-radius: 5px;
	box-shadow: 1px 1px 1px #000000;
	cursor: pointer;
	height: 30px;
	margin-top: -11px;
	width: 15px;
}
input[type='range']:focus::-webkit-slider-runnable-track {
	background: #007167;
}
input[type='range']::-moz-range-track {
	background: #007167;
	border: 1px solid #000000;
	border-radius: 5px;
	box-shadow: 1px 1px 1px #000000;
	cursor: pointer;
	height: 10px;
	width: 100%;
}
input[type='range']::-moz-range-thumb {
	background: #ee7600;
	border: 1px solid #000000;
	border-radius: 5px;
	box-shadow: 1px 1px 1px #000000;
	cursor: pointer;
	height: 30px;
	width: 15px;
}
input[type='range']::-ms-track {
	background: transparent;
	border-color: transparent;
	color: transparent;
	cursor: pointer;
	height: 10px;
	width: 100%;
}
input[type='range']::-ms-fill-lower {
	background: #007167;
	border: 1px solid #000000;
	border-radius: 10px;
	box-shadow: 1px 1px 1px #000000;
}
input[type='range']::-ms-fill-upper {
	background: #007167;
	border: 1px solid #000000;
	border-radius: 10px;
	box-shadow: 1px 1px 1px #000000;
}
input[type='range']::-ms-thumb {
	background: #ee7600;
	border: 1px solid #000000;
	border-radius: 5px;
	box-shadow: 1px 1px 1px #000000;
	cursor: pointer;
	height: 30px;
	margin-top: 1px;
	width: 15px;
}
input[type='range']:focus::-ms-fill-lower {
	background: #007167;
}
input[type='range']:focus::-ms-fill-upper {
	background: #007167;
}
