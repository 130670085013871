html {
	height: 100%;
}

body {
	background-color: '#ff352d';
	display: flex;
	height: 100%;
	margin: 0;
	padding: 0;
	padding-bottom: constant(safe-area-inset-bottom); /* iOS 11.0 */
	padding-bottom: env(safe-area-inset-bottom); /* iOS 11.2 */
	padding-top: constant(safe-area-inset-top); /* iOS 11.0 */
	padding-top: env(safe-area-inset-top); /* iOS 11.2 */
}
body #root {
	display: flex;
	flex-flow: column;
	height: 100%;
	width: 100%;
}

@media (min-height: 770px) and (min-width: 650px) {
	/* body #root {
    background-image: url('/images/fondo-general.jpg');
  } */
	body #layout {
		border-color: rgb(158, 54, 103);
		border-radius: 5px;
		box-shadow: 0px 4px 15px 5px;
		/* border-style: solid;
    border-width: 1px; */
	}
}

html,
body {
	text-size-adjust: none;
}

.MuiPickersDay-current {
	color: #649544 !important;
}

/* Fonts */

@font-face {
	font-family: Lato-Light;
	src: url('./assets/fonts/Lato-Light.ttf') format('truetype');
}

@font-face {
	font-family: Lato-Regular;
	src: url('./assets/fonts/Lato-Regular.ttf') format('truetype');
}

@font-face {
	font-family: Lato-Bold;
	src: url('./assets/fonts/Lato-Bold.ttf') format('truetype');
}

/* Fix ---------------------------------------------------------------------*/

/* IMPORTANTE: Fix Safari height on flex */
div {
	flex-shrink: 0;
}

button:focus {
	outline: -webkit-focus-ring-color auto 0px;
}

td {
	padding: 5px;
}
