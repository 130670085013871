.vanilla-jsoneditor-react {
	display: flex;
	flex: 1;
	width: 100%;
}

.jse-contents,
.jse-tree-mode.no-main-menu {
	border: 0 !important;
}
